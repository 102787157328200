<template>
  <div>
    <!-- 采购明细查询 -->
    <filter-view @submit="reQuery">
      <div>
        <div class="c h padding-10">
          <div class="no-flex">省份</div>
          <div class="flex"></div>
          <quick-select
            v-model="query.provinceCode"
            clearable
            url="api/regionalDict/province"
            value-field="code"
            placeholder="省份"
            filterable
            style="width: 130px"
            class="padding-0-05"
            size="mini"
            @change="query.cityCode = null"
          />
          <quick-select
            v-model="query.cityCode"
            clearable
            :url="`api/regionalDict/parent/${query.provinceCode}`"
            value-field="code"
            placeholder="城市"
            filterable
            v-if="query.provinceCode"
            style="width: 130px"
            size="mini"
          />
          <el-input
            v-else
            disabled
            placeholder="请先选择省份"
            style="width: 130px"
            size="mini"
          />
        </div>
        <div class="c h padding-10">
          <div class="no-flex">经销商</div>
          <div class="flex"></div>
          <quick-select
            v-model="query.buyerId"
            url="api/distributor"
            placeholder="经销商"
            clearable
            filterable
            @change="reQuery"
            value-field="enterpriseId"
            style="width: 130px"
            size="mini"
          />
          <quick-select
            v-model="query.buyerErpId"
            url="api/distributorErps/list"
            :params="{ entId: query.buyerId }"
            placeholder="下单账户"
            value-field="erpId"
            display-field="name"
            style="width: 130px; margin-left: 5px"
            clearable
            size="mini"
            v-if="query.buyerId"
          />
        </div>
        <div class="c h padding-10">
          <div class="no-flex">日期</div>
          <div class="flex"></div>
          <div class="h c" key="range-date">
            <el-date-picker
              v-model="query.beginTime"
              size="mini"
              type="date"
              value-format="timestamp"
              placeholder="开始日期"
              :clearable="false"
              style="width: 125px"
            />
            <div class="padding-0-05">至</div>
            <el-date-picker
              v-model="query.endTime"
              size="mini"
              type="date"
              value-format="timestamp"
              placeholder="结束日期"
              :clearable="false"
              style="width: 125px"
            />
          </div>
        </div>
        <div class="c h padding-10">
          <template v-if="checkPermission(['WHOLESALE_ALL', 'WHOLESALE_PAY'])">
            <el-select
              v-model="query.payType"
              placeholder="支付方式"
              style="width: 130px"
              size="mini"
              clearable
              @change="
                query.payStatus = null;
                reQuery();
              "
            >
              <el-option
                v-for="(v, i) in payTypes"
                :key="i"
                :label="v"
                :value="i"
              />
            </el-select>
            <el-select
              v-model="query.payStatus"
              placeholder="付款状态"
              class="padding-0-05"
              style="width: 130px"
              size="mini"
              clearable
              @change="reQuery"
              :disabled="query.payType !== 0 && query.payType !== 1"
            >
              <template v-for="(item, i) in payStatus[query.payType]">
                <el-option
                  v-if="item.hidden !== true"
                  :key="i"
                  :label="item.label"
                  :value="i"
                />
              </template>
            </el-select>
            <el-select
              v-model="query.status"
              placeholder="订单状态"
              style="width: 130px"
              size="mini"
              clearable
              @change="reQuery"
            >
              <el-option
                v-for="item in status"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </template>
        </div>
        <div class="c h padding-10">
          <el-input
            v-model="query.formCode"
            :maxlength="20"
            placeholder="订单号"
            @keypress.enter.native="reQuery"
            style="width: 130px"
            size="mini"
          />
          <el-input
            v-model="query.goodsName"
            :maxlength="20"
            placeholder="商品ERP编码或名称"
            @keypress.enter.native="reQuery"
            style="width: 130px"
            class="padding-0-05"
            size="mini"
          />
          <el-input
            v-model="query.info"
            :maxlength="20"
            placeholder="备注"
            @keypress.enter.native="reQuery"
            style="width: 130px"
            size="mini"
          />
        </div>
        <div class="c h padding-10">
          <div style="line-height: 32px">查看未出货商品:</div>
          <div class="flex"></div>
          <el-switch v-model="query.unsend" @change="reQuery" size="mini" />
        </div>
      </div>
    </filter-view>
    <el-card shadow="never" v-if="count">
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">原单金额</div>
          <counter
            class="num"
            :end-val="count.amount / 100 || 0"
            :decimals="2"
            :duration="1000"
          />
        </div>
        <div class="money-count-item">
          <div class="fc-g">销售金额</div>
          <counter
            class="num"
            :end-val="(count.amount - count.discountAmount) / 100 || 0"
            :decimals="2"
            :duration="1000"
          />
        </div>
      </div>
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">使用返利金额</div>
          <counter
            class="num"
            :class="count.rebateAmount > 0 ? 'fc-e' : ''"
            :end-val="count.rebateAmount / 100 || 0"
            :decimals="2"
            :duration="1000"
          />
        </div>
        <div class="money-count-item">
          <div class="fc-g">优惠金额</div>
          <counter
            class="num"
            :class="count.discountAmount > 0 ? 'fc-e' : ''"
            :end-val="count.discountAmount / 100 || 0"
            :decimals="2"
            :duration="1000"
          />
        </div>
      </div>
    </el-card>
    <!-- 表格 -->
    <div class="padding-10" v-if="storeData && storeData.length > 0">
      <div>数据列表</div>
      <div v-for="(o, i) in storeData" :key="i" class="text item padding-10-0">
        <div class="c h">
          <el-card
            class="box-card fs-small lh-150"
            shadow="never"
            style="width: 100%"
          >
            <div>
              销售单号<span class="padding-0-05" />{{ o.formCode || "--" }}
            </div>
            <div>
              零售单单号<span class="padding-0-05" />{{ o.sourceCode || "--" }}
            </div>
            <div>
              订单日期<span class="padding-0-05" />{{
                new Date(o.purchaseTime).format("yyyy/MM/dd") || "--"
              }}
            </div>
            <div>
              经销商编码<span class="padding-0-05" />{{ o.buyerErpId || "--" }}
            </div>
            <div>
              经销商<span class="padding-0-05" />{{ o.buyerName || "--" }}
            </div>
            <div>商品编码<span class="padding-0-05" />{{ o.code || "--" }}</div>
            <div>
              ERP编码<span class="padding-0-05" />{{ o.erpCode || "--" }}
            </div>
            <div>
              商品<span class="padding-0-05" />{{ o.goodsName || "--" }}
            </div>
            <div>
              规格<span class="padding-0-05" />{{
                $goodsSpecConvert(o.goodsSpec) || "--"
              }}
            </div>
            <div>数量<span class="padding-0-05" />{{ o.count || "--" }}</div>
            <div>
              出厂价<span class="padding-0-05" />{{ $price(o.price) || "--" }}
            </div>
            <div>
              折扣率<span class="padding-0-05" />{{
                calcProductDiscountPercent(o) || "--"
              }}
            </div>
            <div>
              成交价<span class="padding-0-05" />{{
                $price(o.realPrice) || "--"
              }}
            </div>
            <div>
              金额<span class="padding-0-05" />{{
                $price(o.realPrice * (o.count - o.cancelCount)) || "--"
              }}
            </div>
            <div>
              整单折扣率<span class="padding-0-05" />{{
                calcDiscountPercent(o) || "--"
              }}
            </div>
            <div>
              已发货数量<span class="padding-0-05" />{{ o.sendCount || "--" }}
            </div>
            <div>
              已收货数量<span class="padding-0-05" />{{
                o.receivedCount || "--"
              }}
            </div>
            <div>
              已取消数量<span class="padding-0-05" />{{ o.cancelCount || "--" }}
            </div>
            <div>
              发货日期<span class="padding-0-05" />{{
                new Date(o.shipDate).format("yyyy/MM/dd") || "--"
              }}
            </div>
            <div>
              支付方式<span class="padding-0-05" />{{
                payTypes[o.payType] || "--"
              }}
            </div>
            <div>
              付款情况<span class="padding-0-05" />
              <span :key="o.id">{{
                payStatus[o.payType][o.payStatus].label || "--"
              }}</span>
            </div>
            <div>
              关闭状态<span class="padding-0-05" />
              <span v-if="o.count == o.cancelCount">已关闭</span>
              <span v-else-if="0 < o.cancelCount && o.cancelCount < o.count"
                >部分已关闭</span
              >
              <span v-else>未关闭</span>
            </div>
            <div>
              订单状态<span class="padding-0-05" /><span>{{
                status[o.formStatus].label || "--"
              }}</span>
            </div>
          </el-card>
        </div>
      </div>
      <div class="ta-c">
        <el-pagination
          :total="totalElements"
          :current-page="page"
          :page-size="size"
          small
          layout="prev, next"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import filterView from "../../statistics/assembly/filter";
import request from "@/utils/request";
import { sumDetails } from "@/api/distributorOrder";
import Counter from "vue-count-to";
export default {
  components: {
    filterView,
    Counter,
  },
  data() {
    return {
      page: 1,
      size: 6,
      totalElements: null,
      params: null,
      count: {},
      storeData: [],
      payTypes: ["线上支付", "线下支付"],
      payStatus: [
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分付款成功",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "付款成功",
          },
        ],
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分已付款",
          },
          {
            type: "warning",
            label: "部分付款已确认",
          },
          {
            type: "success",
            label: "已付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "已完成",
          },
        ],
      ],
      status: [
        {
          type: "info",
          label: "编辑中",
          id: 0,
        },
        {
          type: "warning",
          label: "待审批",
          id: 1,
        },
        {
          type: "success",
          label: "已确认",
          id: 2,
        },
        {
          type: "danger",
          label: "已拒绝",
          id: 3,
        },
        {
          type: "warning",
          label: "待发货",
          id: 4,
        },
        {
          type: "success",
          label: "部分已发货",
          id: 5,
        },
        {
          type: "success",
          label: "已发货",
          id: 6,
        },
        {
          type: "success",
          label: "部分已收货",
          id: 7,
        },
        {
          type: "success",
          label: "已完成",
          id: 8,
        },
        {
          type: "info",
          label: "已关闭",
          id: 9,
        },
        {
          type: "info",
          label: "已取消",
          id: 10,
        },
        {
          type: "info",
          label: "部分已关闭",
          id: 11,
        },
      ],
      query: {
        provinceCode: null,
        cityCode: null,
        buyerId: null,
        beginTime: null,
        endTime: null,
        payStatus: null,
        status: null,
        formCode: null,
        goodsName: null,
        unsend: false,
      },
    };
  },
  created() {
    this.reQuery();
  },
  methods: {
    checkPermission,
    handleCurrentChange(val) {
      this.page = val;
      this.toQuery();
      this.topFunction();
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    toQuery() {
      let query = JSON.parse(JSON.stringify(this.query));
      this.params = Object.assign({ sort: "purchaseTime,desc" }, query, {
        page: this.page - 1,
        size: this.size,
      });
      request({
        url: "api/orderForm/detail/seller",
        method: "get",
        params: this.params,
      })
        .then((res) => {
          this.storeData = res.content;
          this.totalElements = res.totalElements;
        })
        .catch((err) => {
          this.storeData = [];
        });
    },

    reQuery() {
      this.toQuery();
      this.loadSums();
    },
    // beforeInit() {
    //   this.url = "api/orderForm/detail/seller";
    //   let query = JSON.parse(JSON.stringify(this.query));
    //   this.params = Object.assign({ sort: this.sort }, query);
    //   return true;
    // },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      // this.toQuery();
    },
    loadSums() {
      sumDetails(this.params).then((res) => {
        this.count = res;
      });
    },

    calcProductDiscountPercent(row) {
      let dm = row.price - row.realPrice;
      if (dm <= 0) return "100%";
      else return ((row.realPrice / row.price) * 100).toFixed(2) + "%";
    },
    calcDiscountPercent(row) {
      let dm = row.formTotalMoney - row.formRealTotalMoney;
      if (dm <= 0) return "100%";
      else
        return (
          ((row.formRealTotalMoney / row.formTotalMoney) * 100).toFixed(2) + "%"
        );
    },
    edit(data) {
      this.$refs.form && this.$refs.form.resetForm(data.formId);
    },
  },
};
</script>
